<template>
  <div>
    <div style="height: calc(100vh - 50px)" class="overflow-y-auto hidescroll">
      <v-expansion-panels
        accordion
        v-model="expantionModel"
        flat
        multiple
        focusable
      >
        <v-expansion-panel>
          <v-expansion-panel-header
            style="height: 40px"
            color="rgb(238, 240, 248)"
            class="primary--text"
            ><span class="font-weight-bold">{{
              sections[0]
            }}</span></v-expansion-panel-header
          >
          <v-expansion-panel-content class="pt-2">
            <v-row class="mx-0 pa-0">
              <v-col cols="12" class="ma-0 px-0 py-2"
                ><v-autocomplete
                  v-model="doctor"
                  clearable
                  hide-details
                  dense
                  outlined
                  :items="$doctor.getLocalDocs()"
                  :filter="$util.doctorFilter"
                  label="By Doctor"
                  placeholder="Doctor"
                  return-object
                >
                  <template v-slot:selection="data">
                    {{ data.item.doctor_id.first_name }}
                    {{ data.item.doctor_id.last_name }}
                  </template>
                  <template v-slot:item="data">
                    {{ data.item.doctor_id.first_name }}
                    {{ data.item.doctor_id.last_name }}
                  </template>
                </v-autocomplete></v-col
              >

              <v-col cols="12" class="ma-0 px-0 py-2">
                <v-autocomplete
                  v-model="department"
                  clearable
                  hide-details
                  dense
                  outlined
                  :items="departments"
                  :filter="$util.departmentFilter"
                  label="By Department"
                  placeholder="Department"
                  return-object
                >
                  <template v-slot:selection="data">
                    <v-avatar
                      style="
                        min-width: 18px !important;
                        min-height: 18px !important;
                      "
                      height="18px"
                      width="18px"
                      max-height="18px"
                      max-width="18px"
                      color="#d2e1f2"
                      left
                    >
                      <v-img
                        contain
                        height="10"
                        v-if="data.item.avatar"
                        :src="$root.asseturl + data.item.avatar.private_hash"
                      ></v-img>
                      <div
                        v-else
                        style="text-transform: uppercase; width: 100%"
                        class="primary--text font-size-h4"
                      >
                        {{ data.item.name.charAt(0) }}
                      </div>
                    </v-avatar>
                    {{ $util.getTranslation(data.item).name }}
                  </template>
                  <template v-slot:item="data">
                    <v-avatar
                      style="
                        min-width: 18px !important;
                        min-height: 18px !important;
                      "
                      class="pushx"
                      height="18px"
                      width="18px"
                      max-height="18px"
                      max-width="18px"
                      color="#d2e1f2"
                      left
                    >
                      <v-img
                        contain
                        height="10"
                        v-if="data.item.avatar"
                        :src="$root.asseturl + data.item.avatar.private_hash"
                      ></v-img>
                      <div
                        v-else
                        style="text-transform: uppercase; width: 100%"
                        class="primary--text"
                      >
                        {{ data.item.name.charAt(0) }}
                      </div>
                    </v-avatar>
                    {{ $util.getTranslation(data.item).name }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col class="ma-0 px-0 py-2" cols="12">
                <v-menu
                  v-model="created_on_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="created_on"
                      label="By asked on"
                      placeholder="Select date"
                      readonly
                      clearable
                      hide-details
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="created_on"
                    @input="created_on_menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="ma-0 px-0 py-2" cols="12">
                <v-menu
                  v-model="modified_on_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="modified_on"
                      label="By answered on"
                      placeholder="Select date"
                      readonly
                      clearable
                      hide-details
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="modified_on"
                    @input="modified_on_menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                By Status
                <v-chip-group active-class="primary--text" v-model="status">
                  <v-chip
                    v-for="item in statusOptions"
                    :key="item.id"
                    color="#d2e1f2"
                    small
                    class="primary--text"
                    active-class="white--text yellow darken-3"
                    filter
                  >
                    {{ item.title }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            style="height: 40px"
            color="rgb(238, 240, 248)"
            class="primary--text"
            ><span class="font-weight-bold">{{
              sections[1]
            }}</span></v-expansion-panel-header
          >
          <v-expansion-panel-content class="pt-2">
            <v-row class="mx-0 pa-0">
              <v-col cols="12" class="ma-0 pa-0">
                Sort by
                <v-chip-group active-class="primary--text" v-model="sort">
                  <v-chip
                    v-for="item in sortOptions"
                    :key="item.id"
                    color="#d2e1f2"
                    small
                    class="primary--text"
                    active-class="white--text yellow darken-3"
                    filter
                  >
                    {{ item.title }}
                  </v-chip>
                </v-chip-group>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                Order
                <v-chip-group active-class="primary--text" v-model="order">
                  <v-chip
                    v-for="item in orderOptions"
                    :key="item.id"
                    color="#d2e1f2"
                    small
                    class="primary--text"
                    active-class="white--text yellow darken-3"
                    filter
                  >
                    {{ item.title }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-row style="height: 45px" class="px-3 ma-0 py-0">
      <v-col class="ma-0 py-0 px-1">
        <v-btn
          @click="reset()"
          block
          color="primary"
          rounded
          outlined
          elevation="0"
          >Reset</v-btn
        >
      </v-col>
      <v-col class="ma-0 py-0 px-1">
        <v-btn
          @click="apply()"
          block
          color="primary"
          rounded
          elevation="0"
          class="white--text"
          >Apply</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {},
  name: "QuestionsFilter",
  data() {
    return {
      sections: ["Filters", "Sort"],
      statusOptions: [
        { id: 1, title: "Answered", value: "answered" },
        { id: 2, title: "Not answered", value: "notanswered" }
      ],
      sortOptions: [
        { id: 1, title: "Asked on", value: "created_on" },
        { id: 2, title: "Answered On", value: "modified_on" }
      ],
      orderOptions: [
        { id: 1, title: "Acending", value: "" },
        { id: 2, title: "Decending", value: "-" }
      ],
      expantionModel: [0, 1],

      created_on_menu: false,
      modified_on_menu: false,

      //filters
      doctor: undefined,
      status: undefined,
      department: undefined,
      created_on: undefined,
      modified_on: undefined,
      //sort
      order: undefined,
      sort: undefined
    };
  },

  created() {},
  watch: {},
  methods: {
    reset() {
      this.doctor = undefined;
      this.status = undefined;
      this.department = undefined;
      this.created_on = undefined;
      this.modified_on = undefined;
      //sort
      this.order = undefined;
      this.sort = undefined;
      this.$emit("resetFilters", true);
    },
    apply() {
      console.log("created_on", this.created_on);
      console.log("modified_on", this.modified_on);

      let toreturn = { sort: undefined, filters: {} };
      if (this.sort !== undefined)
        toreturn.sort =
          this.order !== undefined
            ? this.orderOptions[this.order].value.concat(
                this.sortOptions[this.sort].value
              )
            : this.sortOptions[this.sort].value;
      if (this.status !== undefined)
        toreturn.filters.status = {
          operator: "eq",
          value: this.statusOptions[this.status].value
        };
      if (this.doctor !== undefined)
        toreturn.filters.doctor = { operator: "eq", value: this.doctor.id };
      if (this.department !== undefined)
        toreturn.filters.department = {
          operator: "eq",
          value: this.department.id
        };
      if (this.created_on !== undefined && this.created_on !== null)
        toreturn.filters.created_on = {
          operator: "contains",
          value: this.created_on
        };
      if (this.modified_on !== undefined && this.modified_on !== null) {
        toreturn.filters.modified_on = {
          operator: "contains",
          value: this.modified_on
        };
        toreturn.filters.status = { operator: "eq", value: "answered" };
      }

      console.log(toreturn);
      this.$emit("updatefilters", toreturn);
    }
  },
  computed: {
    departments() {
      return this.$department.data.departments.data.filter(department => {
        return department.doctors.length > 0;
      });
    }
  },
  mounted() {}
};
</script>

<style></style>
